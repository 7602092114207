import Glide from '@glidejs/glide'

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.glide')) {
    return;
  }

  new Glide('.glide', {
    autoplay: 4000,
    perView: 2,
    gap: 16,
    breakpoints: {
      1024: {
        perView: 2
      },
      600: {
        perView: 1
      }
    }
  }).mount()
})
